import { useContext, useState } from 'react';
import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  Collapse,
  Divider,
  useTheme,
  darken
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'src/reducers/common';
import { sidebarItems } from 'src/utils/_nav';
import {
  MenuWrapper,
  SubMenuWrapper
} from 'src/assets/styledComponent/SidebarStyled';

function SidebarMenu() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { closeSidebar } = useContext(SidebarContext);
  const [meterDataOpen, setMeterDataOpen] = useState(false);
  const [routingOpen, setRoutingOpen] = useState(false);
  const [rtcReportOpen, setRtcReportOpen] = useState(false);
  const [nmsOpen, setNmsOpen] = useState(false);

  const handleMeterDataClick = () => {
    setMeterDataOpen(!meterDataOpen);
  };
  const handleRoutingClick = () => {
    setRoutingOpen(!routingOpen);
  };
  const handleRTCReportClick = () => {
    setRtcReportOpen(!rtcReportOpen);
  };
  const handleNMSClick = () => {
    setNmsOpen(!nmsOpen);
  };
  const handleSidebar = (title: string) => {
    closeSidebar();
    dispatch(setPageTitle(title));
  };

  return (
    <>
      <MenuWrapper
        sx={{
          overflowX: 'hidden'
        }}
      >
        <SubMenuWrapper >
          <List component="div">
            {sidebarItems.map((item) => (
              <ListItem 
                component="div"
                key={item.name}
              >
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={() => handleSidebar(item.name)}
                  to={item.path}
                  startIcon={<item.icon />}
                >
                  {item.name}
                </Button>
              </ListItem>
            ))}
          </List>
        </SubMenuWrapper>
      </MenuWrapper>

      {/* <Collapse in={routingOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem component="div" sx={{ pl: 4 }}>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/meter-data/option1"
                    startIcon={<RoutingsIcon />}
                  >
                    Option 1
                  </Button>
                </ListItem>
                <ListItem component="div" sx={{ pl: 4 }}>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/meter-data/option2"
                    startIcon={<RoutingsIcon />}
                  >
                    Option 2
                  </Button>
                </ListItem>
              </List>
            </Collapse> */}
      {/* </List>
        </SubMenuWrapper> */}
      {/* </List> */}

      {/* <Box sx={{ background: 'transparent', height: '130px' }} /> */}

      {/* Settings and Log Out */}
      {/* <MenuWrapper className="settings-menu">
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/settings"
                  startIcon={<SettingIcon />}
                >
                  Settings
                </Button>
              </ListItem>
              <Divider
                sx={{
                  mx: theme.spacing(2),
                  background: theme.colors.alpha.trueWhite[10]
                }}
              />
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/logout"
                  startIcon={<LogOutIcon />}
                >
                  Log Out
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper> */}
    </>
  );
}

export default SidebarMenu;
