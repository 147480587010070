import { createSlice } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
  rawDataList: []
};

const rawDataViewerSlice = createSlice({
  name: 'rawDataViewer',
  initialState,
  reducers: {
    setAllRawDataList: (state, action) => {
      state.rawDataList = action.payload;
    }
  }
});

export const { setAllRawDataList } = rawDataViewerSlice.actions;
export default rawDataViewerSlice.reducer;
