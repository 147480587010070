import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import PrivateRoute from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const HESDashboard = Loader(
  lazy(() => import('src/views/pages/Dashboard/index'))
);
const Meters = Loader(lazy(() => import('src/views/pages/Meters/index')));
const MeterDetail = Loader(
  lazy(() => import('src/views/pages/Meters/MeterDetail/index'))
);
const Events = Loader(lazy(() => import('src/views/pages/Events/index')));
const Reports = Loader(lazy(() => import('src/views/pages/Reports/index')));
const Routings = Loader(lazy(() => import('src/views/pages/Routings/index')));
const NMS = Loader(lazy(() => import('src/views/pages/NMS/index')));
const RawDataViewer = Loader(
  lazy(() => import('src/views/pages/Raw-Data/index'))
);
const MeterDataViewer = Loader(
  lazy(() => import('src/views/pages/Meter-Data/index'))
);
const VEEExpression = Loader(
  lazy(() => import('src/views/pages/VEE-Expression'))
);
const CommandsDataViewer = Loader(
  lazy(() => import('src/views/pages/HES-Commands/index'))
);
const UserManagement = Loader(
  lazy(() => import('src/views/pages/User-Management/index'))
);
const GatewayManagement = Loader(
  lazy(() => import('src/views/pages/Gateway-Management/index'))
);
const AddEditVeeExpression = Loader(
  lazy(() => import('src/views/pages/VEE-Expression/AddEditVeeExpression'))
);
const Status404 = Loader(
  lazy(() => import('src/views/pages/Status/Status404'))
);
const Login = Loader(lazy(() => import('src/views/session-pages/login')));
const ForgotPassword = Loader(
  lazy(() => import('src/views/session-pages/forgot-password'))
);
const SentEmail = Loader(
  lazy(() => import('src/views/session-pages/sentEmail'))
);
const ResetPassword = Loader(
  lazy(() => import('src/views/session-pages/reset-password'))
);
const SuccesStatus = Loader(
  lazy(() => import('src/views/session-pages/resetStatus'))
);
const FailStatus = Loader(
  lazy(() => import('src/views/session-pages/resetStatus'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" replace />
      },
      {
        path: 'login',
        // element: <Login />
        element: <PublicRoute element={<Login/>} />
      },
      {
        path: 'forgot-password',
        // element: <ForgotPassword />
        element: <PublicRoute element={<ForgotPassword/>} />
      },
      {
        path: 'sent-email',
        // element: <SentEmail />
        element: <PublicRoute element={<SentEmail/>} />
      },
      {
        path: 'reset-password',
        // element: <ResetPassword />
        element: <PublicRoute element={<ResetPassword/>} />
      },
      {
        path: 'reset-status/success',
        // element: <SuccesStatus />
        element: <PublicRoute element={<SuccesStatus/>} />
      },
      {
        path: 'reset-status/fail',
        // element: <FailStatus />
        element: <PublicRoute element={<FailStatus/>} />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: 'dashboard',
        element: <PrivateRoute element={HESDashboard} />
      },
      {
        path: 'meters',
        element: <PrivateRoute element={Meters} />
      },
      {
        path: 'meter-detail/:id',
        element: <PrivateRoute element={MeterDetail} />
      },
      {
        path: 'raw-data',
        element: <PrivateRoute element={RawDataViewer} />
      },
      {
        path: 'meter-data',
        element: <PrivateRoute element={MeterDataViewer} />
      },
      {
        path: 'events',
        element: <PrivateRoute element={Events} />
      },
      {
        path: 'reports',
        element: <PrivateRoute element={Reports} />
      },
      {
        path: 'routing',
        element: <PrivateRoute element={Routings} />
      },
      {
        path: 'nms',
        element: <PrivateRoute element={NMS} />
      },
      {
        path: 'hes-commands',
        element: <PrivateRoute element={CommandsDataViewer} />
      },
      {
        path: 'vee-expression',
        element: <PrivateRoute element={VEEExpression} />
      },
      {
        path: 'edit-vee-expression',
        element: <PrivateRoute element={AddEditVeeExpression} />
      },
      {
        path: 'add-vee-expression',
        element: <PrivateRoute element={AddEditVeeExpression} />
      },
      {
        path: 'user-management',
        element: <PrivateRoute element={UserManagement} />
      },
      {
        path: 'gateway-management',
        element: <PrivateRoute element={GatewayManagement} />
      }
    ]
  }
];

export default routes;
