import { createSlice } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
  eventDataList: [],
  eventData: [],
  eventProfiles: [],
};

const eventsViewerSlice = createSlice({
  name: 'eventViewer',
  initialState,
  reducers: {
    setAllEventDataList: (state, action) => {
      state.eventDataList = action.payload;
    },
    setAllEventData: (state, action) => {
      state.eventData = action.payload;
    },
    setAllEventProfiles: (state, action) => {
      state.eventProfiles = action.payload;
    }
  }
});

export const { setAllEventDataList, setAllEventData, setAllEventProfiles } =
  eventsViewerSlice.actions;
export default eventsViewerSlice.reducer;
