import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { PeopleIcon } from 'src/assets/svg/svg';
import { clearLocalStorage, getLocalStorage } from 'src/utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { logOutAction } from 'src/api/auth';
import { useTheme } from '@mui/material/styles';
const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        color: ${theme.colors.alpha.black[100]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
           color: ${theme.colors.alpha.black[100]};
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
       color: ${theme.colors.alpha.black[100]};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
 color: ${theme.colors.alpha.black[80]};
`
);

function HeaderUserbox() {
  const theme = useTheme();
  const { moduleListByRole } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const signOutHandler = async () => {
    // clearLocalStorage();
    await dispatch(logOutAction({ userName: getLocalStorage('userName') }));
    clearLocalStorage();
    navigate('/login');
  };

  return (
    <>
      <Box ref={ref} onClick={handleOpen}>
        <PeopleIcon />
      </Box>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }} 
        sx={{ borderRadius: "0px !important" }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <PeopleIcon />
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {getLocalStorage('userName')}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">
              {moduleListByRole?.roleName?.toUpperCase()}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <Box
          sx={{
            m: 1
          }}
        >
          <Button
            color="primary"
            onClick={() => signOutHandler()}
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              color: `${theme.colors.alpha.black[100]}`,
              background: `${theme.colors.alpha.primary[100]}`
            }}
            fullWidth
          >
            <LockOpenTwoToneIcon />
            <Box marginLeft={1}> Sign out </Box>
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
