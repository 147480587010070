import { createSlice } from '@reduxjs/toolkit';
import { setLocalStorage } from 'src/utils/helper';

// Define the initial state
const initialState = {
  login: {},
  activeSession: {},
  moduleListByRole: {}
};

const authenticationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.login = action.payload; 
    },
    setActiveSession: (state, action) => {
      state.login = action.payload; 
    },
    setModuleListByRole: (state, action) => {
      state.moduleListByRole = action.payload;
    }
  }
});

export const { setLogin, setActiveSession, setModuleListByRole } =
  authenticationSlice.actions;
export default authenticationSlice.reducer;
