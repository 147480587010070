import {
  DashboardIcon,
  GatewayManagementIcon,
  HESCommandsIcon,
  HESRawDataIcon,
  MeterDataIcon,
  MetersIcon,
  ReportsIcon,
  RoutingsIcon
} from 'src/assets/svg/svg';

export const sidebarItems = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: DashboardIcon,
    key: 'dashboard'
  },
  { name: 'Meters', path: '/meters', icon: MetersIcon, key: 'meters' },
  {
    name: 'Meter Data',
    path: '/meter-data',
    icon: MeterDataIcon,
    key: 'meterData'
  },
  {
    name: 'Command Hub',
    path: '/hes-commands',
    icon: HESCommandsIcon,
    key: 'commandHub'
  },
  { name: 'Routings', path: '/routing', icon: RoutingsIcon, key: 'routings' },
  { name: 'Reports', path: '/reports', icon: ReportsIcon, key: 'reports' },
  {
    name: 'HES Raw Data',
    path: '/raw-data',
    icon: HESRawDataIcon,
    key: 'HesRaw'
  },
  { name: 'NMS', path: '/nms', icon: DashboardIcon, key: 'nms' },
  {
    name: 'Gateway Management',
    path: '/gateway-management',
    icon: GatewayManagementIcon,
    key: 'gatewayManagement'
  }
];
