import { createSlice } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
  slaDataList: [],
  timeSlotsList: []
};

const slaViewerSlice = createSlice({
  name: 'slaDataViewer',
  initialState,
  reducers: {
    setAllSlaDataList: (state, action) => {
      state.slaDataList = action.payload;
    },
    setAllTimeSlotList: (state, action) => {
      state.timeSlotsList = action.payload;
    }
  }
});

export const { setAllSlaDataList, setAllTimeSlotList } = slaViewerSlice.actions;
export default slaViewerSlice.reducer;
