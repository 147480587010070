import axios from 'axios';
import api from '../index';
import {
  setAllMetersList,
  getMeterDropdownDetail,
  setDownloadDetails,
  setMeterDetails,
  setMeterEventDetails,
  setMeterProfilesDetail,
  setMeterProfilesData,
  setMeterEntities,
  setMeterRoutings,
  setCommandCategory,
  setCommandListByCategory,
  setMeterCommandsList,
  setMeterAllCommandsList
} from 'src/reducers/Meters';
import { createQueryString } from 'src/utils/helper';

export const getAllMetersList: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const queryString = createQueryString(apiData);
      const res = await api.get(`/Meters/GetMeters?${queryString}`);
      dispatch(setAllMetersList(res?.data));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      console.log('err', error);
      reject(error);
    }
  });
};

export const getMeterDropdownDetailHandler: any =
  (apiData) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const queryString = createQueryString(apiData);
        const res = await api.get(
          `/Meters/GetMeterDropdownDetail?${queryString}`
        );
        dispatch(getMeterDropdownDetail(res?.data));
        resolve(res.data);
      } catch (error) {
        //@ts-ignore
        console.log('err', error);
        reject(error);
      }
    });
  };

export const getMeterDetails: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const queryString = createQueryString(apiData);
      const res = await api.get(`/Meters/GetMeterDetails?${queryString}`);
      dispatch(setMeterDetails(res?.data));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      console.log('err', error);
      reject(error);
    }
  });
};

export const getMeterProfileDetails: any =
  (apiData) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const queryString = createQueryString(apiData);
        const res = await api.get(`/Meters/GetProfileDetails?${queryString}`);
        dispatch(setMeterProfilesDetail(res?.data));
        resolve(res.data);
      } catch (error) {
        //@ts-ignore
        console.log('err', error);
        reject(error);
      }
    });
  };

export const getMeterEventDetails: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const queryString = createQueryString(apiData);
      const res = await api.get(`/Meters/GetProfileDetails?${queryString}`);
      dispatch(setMeterEventDetails(res?.data));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      console.log('err', error);
      reject(error);
    }
  });
};

export const getDownloadDetails: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const queryString = createQueryString(apiData);
      const res = await api.get(`/Meters/GetDownloadDetails?${queryString}`);
      dispatch(setDownloadDetails(res?.data?.meterRoutingList));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      console.log('err', error);
      reject(error);
    }
  });
};

export const sendDownloadRequest: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(`/Meters/SendDownloadRequest`, apiData);
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      console.log('err', error);
      reject(error);
    }
  });
};

export const uploadMeterDetails: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(`/Meters/UpdateMeterDetails`, apiData);
      resolve(res);
    } catch (error) {
      //@ts-ignore
      console.log('err', error);
      reject(error);
    }
  });
};

export const getMeterProfilesData: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(`/Meters/GetMeterProfileData`, apiData);
      dispatch(setMeterProfilesData(res?.data));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      console.log('err', error);
      reject(error);
    }
  });
};

export const getMeterRawData: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const queryString = createQueryString(apiData);
      const res = await api.get(`/HESRawData/GetHESRawData?${queryString}`);
      dispatch(setMeterProfilesData(res?.data));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      console.log('err', error);
      reject(error);
    }
  });
};

export const getMeterEntities: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const queryString = createQueryString(apiData);
      const res = await api.get(`/MeterEntity/GetMeterEntities?${queryString}`);
      dispatch(setMeterEntities(res?.data?.entityList));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      console.log('err', error);
      reject(error);
    }
  });
};

export const getMeterRoutings: any = (apiData) => async (dispatch: any) => {
  // console.log('apiData', apiData);
  return new Promise(async (resolve, reject) => {
    try {
      const queryString = createQueryString(apiData);
      const res = await api.get(`/MeterRouting/GetMeterRouting?${queryString}`);
      dispatch(setMeterRoutings(res?.data?.meterRoutingList));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      console.log('err', error);
      reject(error);
    }
  });
};

export const getCommandCategory: any = (apiData) => async (dispatch: any) => {
  // console.log('apiData', apiData);
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/HESCommand/GetCommandCategory`);
      dispatch(setCommandCategory(res?.data?.commandCategoryList));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      console.log('err', error);
      reject(error);
    }
  });
};

export const getCommandListByCategory: any =
  (apiData) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post(
          `/HESCommand/GetCommandListByCategory`,
          apiData
        );
        dispatch(setCommandListByCategory(res?.data?.commandList));
        resolve(res.data);
      } catch (error) {
        //@ts-ignore
        console.log('err', error);
        reject(error);
      }
    });
  };

export const getMeterAllCommandsList: any =
  (apiData) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post(
          `/HESCommand/GetCommandListByCategory`,
          apiData
        );
        dispatch(setMeterAllCommandsList(res?.data?.commandList));
        resolve(res.data);
      } catch (error) {
        //@ts-ignore
        console.log('err', error);
        reject(error);
      }
    });
  };

export const getMeterCommandsList: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const queryString = createQueryString(apiData);
      const res = await api.get(`/HESCommand/GetHesCommands?${queryString}`);
      dispatch(setMeterCommandsList(res?.data?.hesCommandList));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      console.log('err', error);
      reject(error);
    }
  });
};

export const runMeterCommand: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const queryString = createQueryString(apiData);
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL_COMMANDS}/HES/RequestOnDemandData?${queryString}`
      );
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      console.log('err', error);
      reject(error);
    }
  });
};
