import { useRoutes } from 'react-router-dom';
import router from 'src/routes/router';
import { Provider } from 'react-redux';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-datepicker/dist/react-datepicker.css';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { store } from './store';
import { ToastProvider } from 'react-toast-notifications';
import '../src/assets/css/customStyles.css'
import '../src/assets/fonts/fonts.css'

function App() {
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Provider store={store}>
          <ToastProvider autoDismiss autoDismissTimeout={5000}>
            {content}
          </ToastProvider>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
