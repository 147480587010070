import { format } from 'date-fns';
import moment from 'moment';

export const countCheckedColumns = (columns) => {
  return Object.values(columns).filter(Boolean).length;
};

export const trimPayloadText = (text, maxLength = 40) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
};

export const handleCopyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      alert('Payload copied to clipboard');
    })
    .catch((err) => {
      console.error('Failed to copy: ', err);
    });
};

export const inputStyles = (inputWidth) => ({
  width: inputWidth,
  height: '33px',
  border: `0px solid  #000`,
  borderRadius: '0px',
  paddingLeft: '3px',
  color: '#333'
});

// Utility function to format date columns

export function formatDateColumn(columnId, value) {
  const dateColumns = new Set([
    'rtcDateTimeIst',
    'rtcDateTimeUtc',
    'createdDate',
    'completedOn',
    'createdOn',
    'rtcDatetimeUtc',
    'lastCommunicatedOn',
    'createdDateIst',
    'firstCommunicationOn'
  ]);

  if (dateColumns.has(columnId)) {
    // Parse the date string using moment
    let date = moment(value);

    // Ensure the date is valid before formatting
    if (date.isValid()) {
      // Manually add the IST offset (UTC+5:30)
      if (columnId === 'completedOn') {
        // Convert to IST (Indian Standard Time)
        date = date.add(5, 'hours').add(30, 'minutes');
      }
      // Format date part
      const formattedDate = date.format('DD/MM/YYYY');
      // Format time part with AM/PM
      const formattedTime = date.format('hh:mm:ss a').toUpperCase();

      return `${formattedDate} ${formattedTime}`;
    }
  }
  return value;
}

export const generateUUIDLikeNumber = () => {
  // Helper function to generate a random hex character
  const getRandomHex = () => Math.floor(Math.random() * 16).toString(16);

  // Generating the segments
  const segment1 = Array.from({ length: 8 }, getRandomHex).join('');
  const segment2 = Array.from({ length: 4 }, getRandomHex).join('');
  const segment3 = '4' + Array.from({ length: 3 }, getRandomHex).join(''); // Ensuring the 4th position is '4'
  const segment4 =
    (8 + Math.floor(Math.random() * 4)).toString(16) +
    Array.from({ length: 3 }, getRandomHex).join(''); // Ensuring the first hex of segment 4 is '8', '9', 'A', or 'B'
  const segment5 = Array.from({ length: 12 }, getRandomHex).join('');

  return `${segment1}-${segment2}-${segment3}-${segment4}-${segment5}`;
};

export const isSortable = (columnId) =>
  columnId !== 'metersAction' &&
  columnId !== 'meterDataAction' &&
  columnId !== 'lastCommunicatedOn' &&
  columnId !== 'rtcDate' &&
  columnId !== 'meterNo' &&
  columnId !== 'createdOn' &&
  columnId !== 'nodeId' &&
  columnId !== 'meterNumber' &&
  columnId !== 'veeManage';

export const bodyCellStyle = {
  height: 40,
  padding: '7px, 7px, 6px, 7px'
};
export const headerCellStyle = {
  height: 80,
  padding: '7px, 7px, 6px, 7px'
};

export const createQueryString = (params: Record<string, any>): string => {
  const queryParams = new URLSearchParams();

  const buildParams = (obj: any, parentKey: string = '') => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      const newKey = parentKey ? `${parentKey}[${key}]` : key;

      if (value !== undefined && value !== null) {
        if (typeof value === 'object' && !Array.isArray(value)) {
          // If the object is nested, serialize it as JSON and encode
          queryParams.append(newKey, JSON.stringify(value));
        } else {
          queryParams.append(newKey, value);
        }
      }
    });
  };

  buildParams(params);

  return queryParams.toString();
};

export const currentDate = new Date();
export const currentDay = moment().startOf('day').format('YYYY-MM-DD');

export const initialStartDate = moment()
  .subtract(1, 'days')
  .startOf('day')
  .toDate(); // Yesterday's start of the day
export const initialEndDate = moment().startOf('day').toDate(); // Today's start of the day

export function secondsToMinutes(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  // Pad the remaining seconds with a leading zero if less than 10
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
  return `${minutes}m:${formattedSeconds}s`;
}

export const todayDate = moment().format('YYYY-MM-DD');
export const yesterdayDate = moment().subtract(1, 'days').format('YYYY-MM-DD');

export const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const clearPaginationHistory = () => {
  localStorage.removeItem('paginationHistory');
};

export const updateLocalStorage = (newPaginationState) => {
  let paginationHistory =
    JSON.parse(localStorage.getItem('paginationHistory')) || [];
  paginationHistory.push(newPaginationState);
  localStorage.setItem('paginationHistory', JSON.stringify(paginationHistory));
};
export const statusCode = {
  success: 200,
  Bad: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  server: 500
};

export const deepEqual = (a, b) => {
  if (a === b) return true;

  if (
    typeof a !== 'object' ||
    a === null ||
    typeof b !== 'object' ||
    b === null
  ) {
    return false;
  }

  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (!deepEqual(a[i], b[i])) return false;
    }
    return true;
  }

  if (Object.keys(a).length !== Object.keys(b).length) return false;

  for (const key in a) {
    if (!b.hasOwnProperty(key)) return false;
    if (!deepEqual(a[key], b[key])) return false;
  }

  return true;
};

export function splitRange(range) {
  const [min, max] = range.split(' to ').map(Number);
  return { min, max };
}
export function splitNumber(range) {
  const [min, max] = range.split(' - ').map(Number);
  return { min, max };
}

export const extractIntegers = (inputString) => {
  // Regular expression to find all integers in the string
  const regex = /\d+/;
  // Extract the first match and convert it to an integer
  const match = inputString.match(regex);
  return match ? Number(match[0]) : null; // Return null if no integer is found
};

export function parseStringToArray(inputString) {
  // Split the input string by " or " to get the pairs
  const pairs = inputString.split(' & ');

  // Map over each pair to create the desired objects
  return pairs.map((pair) => {
    const [name, value] = pair.split(' for ');
    return {
      name: value.trim(),
      value: name.trim()
    };
  });
}

export function convertStringToObjects(str) {
  // Split the string by '&' to handle each pair separately
  const pairs = str.split('&').map((item) => item.trim());

  // Convert each pair into an object with 'value' and 'name'
  return pairs.map((pair) => {
    // Split the pair by 'for' to separate the value and the name
    const [value, name] = pair.split('for').map((item) => item.trim());

    // Return the object with 'value' as a number and 'name' as a string
    return {
      value: Number(value),
      name: name
    };
  });
}

export const getMonthStartAndEnd = (dateString) => {
  // Parse the date string using moment
  const date = moment(new Date(dateString));

  // Get the start of the month
  const startOfMonth = date.startOf('month').format('YYYY-MM-DD');

  // Get the end of the month
  const endOfMonth = date.endOf('month').format('YYYY-MM-DD');

  return {
    startOfMonth,
    endOfMonth
  };
};

export const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`
});

export const isAuth = () => {
  return !!localStorage.getItem('isAuthenticated');
};

export const setLocalStorage = (storageKey, data) => {
  try {
    localStorage.setItem(storageKey, JSON.stringify(data));
  } catch (e) {
    console.log(e);
  }
};

export const getLocalStorage = (storageKey) => {
  try {
    if (typeof window !== 'undefined') {
      return JSON.parse(localStorage.getItem(storageKey));
    }
  } catch (e) {
    console.log(e);
  }
};

export const clearLocalStorage = () => {
  try {
    if (typeof window !== 'undefined') {
      return localStorage.clear();
    }
  } catch (e) {
    console.log(e);
  }
};

export const removeItemLocalStorage = (storageKey) => {
  try {
    if (typeof window !== 'undefined') {
      return localStorage.removeItem(storageKey);
    }
  } catch (e) {
    console.log(e);
  }
};

export function includesD1(str) {
  return str.includes('D1');
}
export function includesD2(str) {
  return str.includes('D2');
}
export function includesD3(str) {
  return str.includes('D3');
}

export function includesD2orD3(str, value1, value2) {
  return str.includes(value1) || str.includes(value2);
}

export function extractRanges(input) {
  // Use a regular expression to match the number ranges in the format "number - number"
  const ranges = input.match(/\d+ - \d+/g);
  
  if (ranges) {
    return ranges.map(range => {
      const [start, end] = range.split(" - ").map(Number);
      return { start, end };
    });
  }
  
  return [];
}