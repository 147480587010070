import { Box, IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDownloadDetails, sendDownloadRequest } from 'src/api/Meters';
import {
  NotificationDotIcon,
  NotificationIcon,
  PeopleIcon,
  SettingsIcon,
  UnreadNotificationIcon
} from 'src/assets/svg/svg';
import NotificationDrawer from 'src/components/Drawer';
import { currentDay } from 'src/utils/helper';
import HeaderUserbox from '../../Userbox';

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const dispatch = useDispatch();

  const { downloadList } = useSelector((state: any) => state.meters);

  const [open, setOpen] = React.useState(false);

  const [isAnyUnwatched, setIsAnyUnwatched] = useState(false);
  const [unwatchedItems, setUnwatchedItems] = useState([]);
  const [unwatchedItemsArray, setUnwatchedItemsArray] = useState([]);
  useEffect(() => {
    const hasUnwatchedItems = downloadList.some(
      (item) => item.isWatched === false
    );
    const filteredItems = downloadList.filter(
      (item) => item.isWatched === false
    );
    setUnwatchedItems(filteredItems);
    setIsAnyUnwatched(hasUnwatchedItems);
  }, [downloadList]);

  useEffect(() => {
    fetchNotificationDetails();

    setInterval(() => {
      fetchNotificationDetails();
    }, 1800000); // 30 minutes in milliseconds
  }, []);

  useEffect(() => {
    if (unwatchedItems.length > 0) {
      const newArray = unwatchedItems.map((item) => ({
        date: currentDay, // Gets the current day in "YYYY-MM-DD" format
        createdBy: 'jagroop',
        headerRequired: item.headerRequired, // Assuming this is the selectedColumnsString equivalent
        fileFormat: item.fileFormat,
        requestId: item.requestId
      }));
      setUnwatchedItemsArray(newArray);
    } 
  }, [unwatchedItems]);

  const fetchNotificationDetails = () => {
    dispatch(
      getDownloadDetails({
        createdBy: 'jagroop',
        date: currentDay
      })
    )
      .then((res) => {})
      .catch((err) => {
        console.log('err', err);
      });
  };

  const toggleDrawer = () => {
 
 
    if (  open &&   isAnyUnwatched) {
      const apiData = {
        downloadRequestModelList: unwatchedItemsArray
      };
      dispatch(sendDownloadRequest(apiData))
        .then((res) => { 
          dispatch(
            getDownloadDetails({
              createdBy: 'jagroop',
              date: currentDay
            })
          )
            .then((res) => {})
            .catch((err) => {
              console.log('err', err);
            });
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
 
  setOpen((prevOpen) => !prevOpen);

  };

  return (
    <>
      <Box sx={{ display: { lg: 'flex', xs: 'none' } }}>
        <Tooltip title="Notifications">
          <Box
            className="cursor-pointer"
            component="div"
            marginRight={2}
            onClick={toggleDrawer}
          >
            <IconButton sx={{ padding: '2px', position: 'relative' }}>
              {isAnyUnwatched ? (
                <NotificationIcon />
              ) : (
                <UnreadNotificationIcon />
              )}
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip title="">
          <Box className="cursor-pointer"> 
              <HeaderUserbox /> 
          </Box>
        </Tooltip>
      </Box>

      <NotificationDrawer
        open={open}
        toggleDrawer={toggleDrawer}
        direction={'right'}
      />
    </>
  );
}

export default HeaderNotifications;
