import axios from 'axios';
import {
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage
} from 'src/utils/helper';
let baseURL = process.env.REACT_APP_BASE_URL;
export const instance = axios.create({
  baseURL
});
let isRefreshing = false;
let refreshSubscribers = [];
const handleConfig = (config: any) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers['Authorization'] = `Bearer ${JSON.parse(token)}`;
  }
  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  } else {
    config.headers['Content-Type'] = 'application/json';
  }
  return config;
};

// handle request interceptor
instance.interceptors.request.use(
  (config) => handleConfig(config),
  (error) => Promise.reject(error)
);

// handle response interceptor
instance.interceptors.response.use(
  (response) => {
    if (response.data.accessToken) {
      setLocalStorage('accessToken', response.data.accessToken);
      setLocalStorage('isAuthenticated', response.data.isAuthenticated);
    }
    return response;
  },
  (error) => {
    const errorData = error?.response?.data;

    // Ensure errorData is a string before using includes
    if (typeof errorData === 'string' && errorData.includes('expired')) {
      clearLocalStorage();
      window.location.replace('/login');
      return;
    }

    let login;
    const url = error?.response?.config?.url;
    if (url) {
      const splitUrl = url?.split('/');
      login = splitUrl[splitUrl?.length - 1].toLowerCase();
    }
    if (login !== 'login') {
      const originalRequest = error.config;
      if (error.response?.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve) => {
            refreshSubscribers.push((token) => {
              originalRequest.headers['Authorization'] = `Bearer ${token}`;
              resolve(axios(originalRequest));
            });
          });
        }
        originalRequest._retry = true;
        isRefreshing = true;
        return new Promise((resolve, reject) => {
          const access_token = getLocalStorage('accessToken');
          axios
            .post(`${baseURL}/Authentication/Login`, {
              grantType: 'refresh_token',
              refreshToken: access_token
            })
            .then((response) => {
              const res = response.data;
              setLocalStorage('accessToken', res.accessToken);
              setLocalStorage('isAuthenticated', res.isAuthenticated);
              originalRequest.headers[
                'Authorization'
              ] = `Bearer ${res.accessToken}`;
              resolve(axios(originalRequest));
              refreshSubscribers.forEach((callback) =>
                callback(res.accessToken)
              );
            })
            .catch((err) => {
              clearLocalStorage();
              window.location.replace('/login');
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
              refreshSubscribers = [];
            });
        });
      }
    }
    return Promise.reject(error?.response?.data || 'Something went wrong!');
  }
);

export default instance;
