import { useContext, useState } from 'react';
import { Box, useTheme, Typography, Grid, FormControl } from '@mui/material';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { useSelector } from 'react-redux';
import { ApraavaLogoIcon, NextIcon } from 'src/assets/svg/svg';
import HeaderButtons from './Buttons';
import ProjectFilter from 'src/components/ProjectFilter';
import {
  ProjectSelect,
  ProjectSelectIcon,
  ProjectMenuItem
} from 'src/components/CustomSelectMenu';

function Header() {
  const theme = useTheme();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { pageTitle } = useSelector((state: any) => state.common);
  const { subTitle } = useSelector((state: any) => state.common);
  const { subTitle1 } = useSelector((state: any) => state.common);

  if (pageTitle) localStorage.setItem('title', pageTitle);

  const [anchorEl, setAnchorEl] = useState(null);

  const projectFilterHandler = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <Grid container sx={{ height: '100%', lineHeight: '0px' }}>
      <Grid
        xs={4}
        sm={6}
        md={6.5}
        xl={8}
        sx={{
          borderRight: '1px solid black',
          display: 'flex',
          alignItems: 'center',
          padding: 2
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography
            variant="h1"
            sx={{
              marginLeft: {
                xs: '50px !important',
                md: '50px !important',
                lg: '15px !important',
                xl: '15px !important'
              }
            }}
            className="f-25"
          >
            {pageTitle || localStorage.getItem('title')}
          </Typography>

          {subTitle && (
            <Typography
              variant="h1"
              sx={{
                marginLeft: {
                  xs: '50px !important',
                  md: '50px !important',
                  lg: '15px !important',
                  xl: '15px !important'
                }
              }}
              className="f-27"
            >
              {'>'} {subTitle}
            </Typography>
          )}
          {subTitle1 && (
            <Typography
              variant="h1"
              sx={{
                marginLeft: {
                  xs: '50px !important',
                  md: '50px !important',
                  lg: '15px !important',
                  xl: '15px !important'
                }
              }}
              className="f-28"
            >
              {'>'} {subTitle1}
            </Typography>
          )}
        </Box>
        <HeaderButtons />
      </Grid>

      <Grid xs={4} sm={3} md={3} xl={2} onClick={projectFilterHandler}>
        <FormControl fullWidth>
          <ProjectSelect
            IconComponent={ProjectSelectIcon}
            defaultValue="Project 23"
            displayEmpty
            sx={{ border: '0px solid' }}
            MenuProps={{
              PaperProps: {
                style: {
                  marginTop: '1px',
                  padding: '0',
                  borderRadius: '0px',
                  boxShadow: 'none'
                }
              },
              MenuListProps: {
                style: {
                  marginTop: '1px',
                  padding: '0px',
                  maxHeight: 400,
                  overflowY: 'auto'
                }
              }
            }}
            renderValue={(selected) =>
              !selected ? (
                <Typography color="text.secondary"></Typography>
              ) : (
                selected
              )
            }
          >
            {[].map((project) => (
              <ProjectMenuItem key={project} value={project}>
                {project}
              </ProjectMenuItem>
            ))}
          </ProjectSelect>
        </FormControl>
      </Grid>

      <Grid
        xs={4}
        sm={3}
        md={2.5}
        xl={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderLeft: '1px solid black'
        }}
      >
        <ApraavaLogoIcon />
      </Grid>
    </Grid>
  );
}

export default Header;
