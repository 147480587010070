import { Box, Drawer, IconButton, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { CloseIcon } from 'src/assets/svg/svg';
import { useDispatch, useSelector } from 'react-redux';
import { getDownloadDetails, sendDownloadRequest } from 'src/api/Meters';
import { currentDay } from 'src/utils/helper';
import { useEffect, useState } from 'react';
import AdvancedFilterButton from '../Button/AdvancedFilterButton';

const NotificationDrawer = ({ toggleDrawer, open, direction }) => {
  const theme = useTheme();
 
  const { downloadList } = useSelector((state: any) => state.meters);

  return (
    <>
      <Drawer open={open} onClose={toggleDrawer} anchor={'right'}>
        <Box
          sx={{
            width: {
              md: 460,
              lg: 480
            },
            padding: {
              md: '1.2rem 1.8rem', // Padding for medium screens
              lg: '1.5rem 2rem'
            }
          }}
        >
          <Box mb={2} className="d-flex-justify-between">
            <Typography ml={2} variant="h6" className="f-25">
              Notifications
            </Typography>
            <IconButton onClick={toggleDrawer}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box mb={1} className="d-flex-justify-between">
            <Typography ml={2} variant="h6" className="f-18">
              Today
            </Typography>
          </Box>
          {[...downloadList] // Create a shallow copy of the array
            ?.sort(
              (a, b) =>
                Number(new Date(b.createdOn)) - Number(new Date(a.createdOn))
            ) // Sort by createdOn in descending order
            .map((notification, index) => (
              <Box
                key={index}
                className="notification-box"
                sx={{
                  background: notification?.isWatched
                    ? theme.colors.alpha.trueWhite[100] // Background color when isWatched is true
                    : theme.colors.alpha.primaryAlt[100] // Background color when isWatched is false
                }}
              >
                <Box sx={{ marginBottom: 0.4 }}>
                  <Typography display="inline">Request id &nbsp;</Typography>
                  <Typography variant="button" display="inline">
                    {`${notification?.requestId} `}
                  </Typography>
                  <Typography display="inline">
                    against the download has been generated successfully.
                  </Typography>
                </Box>
                <Typography
                  variant="subtitle1"
                  className="f-15"
                  sx={{ marginBottom: 0.4 }}
                >
                  Download Status{' '}
                  {`: ${notification?.generatedLink ? 'Completed' : 'Pending'}`}
                </Typography>
                <Box>
                  <Typography variant="button" display="inline">
                    File Format : &nbsp;
                  </Typography>
                  <Typography display="inline">
                    {notification?.fileFormat}
                  </Typography>
                </Box>

                <Box mt={1} display={'flex'} justifyContent={'end'}>
                  <AdvancedFilterButton
                    text="Download"
                    customSx={{
                      background: notification?.generatedLink
                        ? theme.colors.alpha.secondary[100]
                        : theme.colors.alpha.trueWhite[100],
                      width: '150px !important',
                      height: '32px !important',
                      padding: '6px 15px',
                      cursor: notification?.generatedLink
                        ? 'pointer'
                        : 'not-allowed'
                    }}
                  />
                </Box>
              </Box>
            ))}
        </Box>
      </Drawer>
    </>
  );
};

export default NotificationDrawer;
