import { createSlice } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
  meterDataList: [],
  allMeterData: [],
  materProfilesList: [],
  materCategoryList: []
};

const meterDataViewerSlice = createSlice({
  name: 'meterDataViewer',
  initialState,
  reducers: {
    setAllMeterDataList: (state, action) => {
      state.meterDataList = action.payload;
    },
    setAllMeterData: (state, action) => {
      state.allMeterData = action.payload;
    },
    setMeterProfilesList: (state, action) => {
      state.materProfilesList = action.payload;
    },
    setMeterCategoryList: (state, action) => {
      state.materCategoryList = action.payload;
    }
  }
});

export const {
  setAllMeterDataList,
  setMeterProfilesList,
  setMeterCategoryList,
  setAllMeterData
} = meterDataViewerSlice.actions;
export default meterDataViewerSlice.reducer;
