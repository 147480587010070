import { createSlice } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
  hesCommandList: [],
  hesCommandData: [],
};

const hesCommandsSlice = createSlice({
  name: 'hesCommands',
  initialState,
  reducers: {
    setAllHESCommandsList: (state, action) => {
      state.hesCommandList = action.payload;
    },
    setAllHESCommandsData: (state, action) => {
      state.hesCommandData = action.payload;
    }
  }
});

export const { setAllHESCommandsList, setAllHESCommandsData } =
  hesCommandsSlice.actions;
export default hesCommandsSlice.reducer;
