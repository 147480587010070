import { createSlice } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
  veeDataList: [],
  allVeeData: [],
  entityList: [],
  getVeeExpById: {},
  updateStatus: ""
};

const veeExpressionSlice = createSlice({
  name: 'VeeExpression',
  initialState,
  reducers: {
    setAllVEEExpressionList: (state, action) => {
      state.veeDataList = action.payload;
    },
    setAllVEEExpressions: (state, action) => {
      state.allVeeData = action.payload;
    },
    setVeeExpressionById: (state, action) => {
      state.getVeeExpById = action.payload;
    },
    setUpdateVeeExpStatus: (state, action) => {
      state.updateStatus = action.payload;
    },
    setAllMeterEntitiesByMeterCategoryProfile: (state, action) => {
      state.entityList = action.payload;
    }
  }
});

export const {
  setAllVEEExpressionList,
  setAllMeterEntitiesByMeterCategoryProfile,
  setVeeExpressionById,
  setUpdateVeeExpStatus,
  setAllVEEExpressions
} = veeExpressionSlice.actions;
export default veeExpressionSlice.reducer;
