import { configureStore } from '@reduxjs/toolkit';
import meterDataViewerSlice from '../reducers/MeterData/index';
import rawDataViewerSlice from '../reducers/RawData/index';
import slaViewerSlice from '../reducers/SlaData/index';
import hesCommandsSlice from '../reducers/HESCommands/index';
import veeExpressionSlice from '../reducers/VEE/index';
import metersSlice from '../reducers/Meters/index';
import eventsViewerSlice from '../reducers/Events/index';
import commonSlice from '../reducers/common/index';
import authenticationSlice from '../reducers/auth/index';

export const store = configureStore({
  reducer: {
    meterData: meterDataViewerSlice,
    rawData: rawDataViewerSlice,
    slaData: slaViewerSlice,
    hesCoammandData: hesCommandsSlice,
    veeExpression: veeExpressionSlice,
    events: eventsViewerSlice,
    meters: metersSlice,
    common: commonSlice,
    auth: authenticationSlice
  }
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
