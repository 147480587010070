import { MenuItem, Select, styled } from '@mui/material';
import { FormControlDownIcon, FormControlUpIcon } from 'src/assets/svg/svg';

export const ProjectSelect = styled(Select)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '0px',
  border: '1px solid #000',
  // borderRight: '0px solid #000',

  '& .MuiSelect-select': {
    padding: '28px 20px 18px 20px !important',
    fontSize: '20px', // Set the desired font size
    fontWeight: '500' // Set the desired font weight
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

export const CustomSelect = styled(Select)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '0px',
  border: '1px solid #000',
  // borderRight: '0px solid #000',
  
  '& .MuiSelect-select': {
  padding: '10px 14px',
  fontSize: '15px', // Set the desired font size
  fontWeight: '500', // Set the desired font weight
  },
  '& .MuiOutlinedInput-notchedOutline': {
  border: 'none'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
  border: 'none'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
  border: 'none'
  }
  }));

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.colors.alpha.primaryAlt[100],
  border: `1px solid ${theme.colors.alpha.black[100]}`,
  borderRadius: '0px',
  fontSize: '15px',
  fontWeight: '600',
  color: theme.colors.alpha.black[100],
  height: '44px',

  '&:hover': {
    backgroundColor: `${theme.colors.alpha.primary[100]} !important`
  },
  '&.Mui-selected': {
    backgroundColor: `${theme.colors.alpha.primary[100]} !important`
  }
  //   '&.Mui-selected:hover': {
  //     backgroundColor: '#bae7ff'
  //   }
}));

export const ProjectMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.colors.alpha.primaryAlt[100],
  border: `1px solid ${theme.colors.alpha.black[100]}`,
  borderRadius: '0px',
  fontSize: '18px !important',
  fontWeight: '500 !important',
  color: theme.colors.alpha.black[100],
  height: '48px',
  paddingLeft: "22px",

  '&:hover': {
    backgroundColor: `${theme.colors.alpha.primary[100]} !important`
  },
  '&.Mui-selected': {
    backgroundColor: `${theme.colors.alpha.primary[100]} !important`
  }
  //   '&.Mui-selected:hover': {
  //     backgroundColor: '#bae7ff'
  //   }
}));

export const CustomSelectIcon = ({ open, ...props }) =>
  open ? (
    <FormControlUpIcon {...props} style={{ marginTop: '6px' }} />
  ) : (
    <FormControlDownIcon {...props} style={{ marginTop: '6px' }} />
  );

export const ProjectSelectIcon = ({ open, ...props }) =>
  open ? (
    <FormControlUpIcon {...props} style={{ marginTop: '9px', marginRight: '9px'  }} />
  ) : (
    <FormControlDownIcon {...props} style={{ marginTop: '9px', marginRight: '12px' }} />
  );
